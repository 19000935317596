








































































































































import { Api, TableState, PagedResults, District } from '@/edshed-common/api'
import Component from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Mixins } from 'vue-property-decorator'
// import DistrictInfo from './components/DistrictInfo.vue'
import AddDistrict from './components/AddDistrict.vue'
import Notes from './components/Notes.vue'

@Component({
  components: {
    AddDistrict,
    Notes
  }
})
export default class DistrictsView extends Mixins(ComponentHelper) {
  private filteredDistrictsData: PagedResults<District> = {
    items: [],
    total: 0
  }

  private searchPhrase: string = ''
  // private detailView = null
  private openedDetailed = []
  private addDistrictVisible: boolean = false

  private loading: boolean = false
  private response: string = ''

  private tableState: TableState = {
    page: 1,
    perPage: 20,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  private districtsData: PagedResults<District> = {
    items: [],
    total: 0
  }

  public async mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      await this.getDistricts()
      await this.searchDidChange()
    }
  }

  public async getDistricts () {
    this.loading = true

    try {
      const res = await Api.getDistricts({
        dir: this.tableState.dir,
        skip: this.tableState.perPage * (this.tableState.page - 1),
        sort: this.tableState.sort,
        take: this.tableState.perPage,
        term: this.tableState.term
      })
      if (res) {
        this.districtsData = res
      }
    } catch (error: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load districts. Please try again.',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }

  public async tablePageChanged (page: number) {
    this.tableState.page = page

    await this.searchDidChange()
  }

  public async tableSortChanged (column: string, dir: 'asc' | 'desc') {
    this.tableState.sort = column
    this.tableState.dir = dir

    await this.searchDidChange()
  }

  public async searchDidChange () {
    if (!this.districtsData) {
      return
    }

    await this.getDistricts()

    this.filteredDistrictsData = this.districtsData
  }

  // public showDetails (district) {
  //   this.detailView = district
  // }

  // public hideDetailView () {
  //   this.detailView = null
  // }

  public showAddDistrict () {
    this.addDistrictVisible = true
  }

  public hideAddDistrict () {
    this.addDistrictVisible = false
    this.getDistricts()
  }
}
